import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor() { }

  loadConfig() {
    // TODO to be implemented, so we can use a dynamic configuration file instead of using static one
    // that requires rebuilding the app in order to change configuration (enviroment.ts)
  }

  get analyticsConfig() {
    return environment.firebase.analytics;
  }

  getConfig(id: string): string {
    return environment[id];
  }

  isProduction(): boolean {
    return environment['production'];
  }
}
