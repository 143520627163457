import { AuthenticatedCFRequest, DatabaseResponse, UnauthenticatedCFRequest } from 'src/libs'
import { CommonFunctionsClient, FirebaseInitializer } from '@cogniapp/common-typescript-frontend-lib'

export class CogniFunctionsClient extends CommonFunctionsClient {

  constructor(firebaseInitializer: FirebaseInitializer) {
    super(firebaseInitializer)
  }

  async authenticatedCall(
    request: AuthenticatedCFRequest
  ): Promise<DatabaseResponse> {
    return this.call('handleAuthenticatedRequest', request)
  }

  async unauthenticatedCall(
    request: UnauthenticatedCFRequest
  ): Promise<DatabaseResponse> {
    return this.call('handleUnauthenticatedRequest', request)
  }
}
