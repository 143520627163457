export class DeviceInfo {
  private deviceUuid: string;
  private appVersion: string;
  private model: string;
  private operatingSystem: number;
  private operatingSystemVersion: string;
  private appBuild: string;
  private languageCode: string;

  constructor(uuid: string, model: string, operatingSystem: string, operatingSystemVersion: string, languageCode: string, appVersion: string, appBuild: string, productionEnv: boolean) {
    this.deviceUuid = uuid;
    this.model = model;
    this.languageCode = languageCode;
    if (operatingSystem === 'ios'){
      this.operatingSystem = 1;
    }
    else if (operatingSystem === 'android'){
      this.operatingSystem = 2;
    }
    else {
      this.operatingSystem = 99; // unknown
    }
    this.operatingSystemVersion = operatingSystemVersion;
    this.appVersion = appVersion;
    this.appBuild = appBuild;
    if (!productionEnv) {
      this.appVersion += '-dev';
    }
  }

  get uuid(): string {
    return this.deviceUuid;
  }

  get version(): string {
    return this.appVersion;
  }

  // for callable functions, these are the keys expected by the database
  toJSON(): any {
    return {
      uuid: this.uuid,
      md: this.model,
      os: this.operatingSystem,
      osv: this.operatingSystemVersion,
      cv: `${this.appVersion}-${this.appBuild}`
    };
  }
}
