import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';
import { enUS, ptBR } from 'date-fns/locale';
import { SupportedLanguage } from '../types/supported-language';
import { GenderCodesEnum } from 'src/libs';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  static supportedLanguages: SupportedLanguage[] = [
    {
      code: 'en-US',
      defaultFor: 'en',
      dateFns: enUS,
      shortDate: 'MM-d',
      longDate: 'MMM-dd-yyyy',
      timeFormat: 'h:mm aaa',
      pickerFormat: 'MMM-DD-YY hh:mmA',
      shortPickerFormat: 'MMMM YYYY',
      pickerDisplayFormat: 'MMM-DD-YYYY hh:mmA',
      shortPickerDisplayFormat: 'MMMM YYYY',
      dayNamesForCalendar: ['S','M','T','W','T','F','S'],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthShortNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      name: 'SETTINGS.LANGUAGES.ENGLISH_US'
    },
    {
      code: 'pt-BR',
      defaultFor: 'pt',
      dateFns: ptBR,
      shortDate: 'd-MMM',
      longDate: 'dd-MMM-yyyy',
      timeFormat: 'HH:mm',
      pickerFormat: 'DD-MMM-YY HH:mm',
      shortPickerFormat: 'MMMM YYYY',
      pickerDisplayFormat: 'DD-MMM-YYYY HH:mm',
      shortPickerDisplayFormat: 'MMMM YYYY',
      dayNamesForCalendar: ['S','T','Q','Q','S','S','D'],
      monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      monthShortNames: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
      name: 'SETTINGS.LANGUAGES.PORTUGUESE_BR'
    }
  ];

  private static _currentLanguage: SupportedLanguage;

  constructor(
    private translateService: TranslateService,
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService) {

    const langs = [];
    LanguageService.supportedLanguages.forEach(language => {
      langs.push(language.code);
    });
    this.translateService.addLangs(langs);
  }

  /**
   * Returns current language which was not necessarily chosen by the user.
   */
  get currentLanguage(): string {
    if (LanguageService._currentLanguage) {
      return LanguageService._currentLanguage.code;
    }
    return this.translateService.currentLang;
  }

  /**
   * Returns the language chosen by the user.
   */
  get languageChosenByUser(): string | undefined {
    if (LanguageService._currentLanguage) {
      return LanguageService._currentLanguage.code;
    }
    return undefined;
  }

  get locale(): string {
    return this.translateService.getBrowserCultureLang();
  }

  get shortDateFormat(): string {
    return LanguageService._currentLanguage.shortDate;
  }

  get longDateFormat(): string {
    return LanguageService._currentLanguage.longDate;
  }

  get timeFormat(): string {
    return LanguageService._currentLanguage.timeFormat;
  }

  get pickerFormat(): string {
    return LanguageService._currentLanguage.pickerFormat;
  }

  get shortPickerFormat(): string {
    return LanguageService._currentLanguage.shortPickerFormat;
  }

  get pickerDisplayFormat(): string {
    return LanguageService._currentLanguage.pickerDisplayFormat;
  }
  
  get shortPickerDisplayFormat(): string {
    return LanguageService._currentLanguage.shortPickerDisplayFormat;
  }

  get dateFns() {
    return LanguageService._currentLanguage.dateFns;
  }

  get monthNames() {
    return LanguageService._currentLanguage.monthNames;
  }

  get monthShortNames() {
    return LanguageService._currentLanguage.monthShortNames;
  }

  get dayNamesForCalendar() {
    return LanguageService._currentLanguage.dayNamesForCalendar;
  }

  translate(key: string) {
    return this.translateService.instant(key);
  }

  translateByGroup(group: string, key: string) {
    return this.translateService.instant([group, key].join('.'));
  }

  translateByGender(key: string, gender: GenderCodesEnum) {
    return this.translateService.instant([key, gender].join('_'));
  }

  async setLanguage(languageCode: string) {
    const supportedLanguage = this.getSupportedLanguage(languageCode);
    if (supportedLanguage) {
      LanguageService._currentLanguage = supportedLanguage;
      await this.localStorageService.saveStringValue('language', LanguageService._currentLanguage.code);
      this.translateService.setDefaultLang(LanguageService._currentLanguage.code);
      this.translateService.use(LanguageService._currentLanguage.code);
    }
  }

  async setInitialLanguage() {
    const languageFromStorage = await this.localStorageService.readStringValue('language');
    let supportedLanguage = this.getSupportedLanguage(languageFromStorage);
    let languageCode: string;
    if (supportedLanguage) {
      languageCode = supportedLanguage.code;
      LanguageService._currentLanguage = supportedLanguage;
    } else {
      const languageFromDevice = await this.deviceService.getDeviceLanguage();
      supportedLanguage = this.getSupportedLanguage(languageFromDevice);

      if(!supportedLanguage) {
        supportedLanguage = this.getSupportedLanguage(this.translateService.getBrowserCultureLang());
      }

      if (supportedLanguage) {
        languageCode = supportedLanguage.code;
      } else {      
        languageCode = 'en-US';
      }   
    }

    this.translateService.setDefaultLang(languageCode);
    this.translateService.use(languageCode);
  }

  private getSupportedLanguage(code: string): any {
    const result = LanguageService.supportedLanguages.find(language => language.code === code);
    if (result) {
      return result;
    }
    if (code && code.length >= 2) {
      const shorterCode = code.slice(0, 2);
      return LanguageService.supportedLanguages.find(language => language.defaultFor === shorterCode);
    }
    return undefined;
  }
}
