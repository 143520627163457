export interface LoggerInterface {
  trace: Function
  debug: Function
  info: Function
  warning: Function
  error: Function
  critical: Function
  alert: Function
  emergency: Function
}
