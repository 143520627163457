export enum AppNotificationTypesEnum {
  TEXT_ONLY = 'TEXT-ONLY',
  CREATE_RPD = 'CREATE-RPD',
  INVITATION_ACCEPTED = 'INVITATION-ACCEPTED',
  INVITATION_REFUSED = 'INVITATION-REFUSED',
  ARCHIVED_BY_THERAPIST = 'ARCHIVED-BY-THERAPIST',
  SEEN_COGNIS = 'SEEN-COGNIS',
  TRIED_ACCESS = 'TRIED-ACCESS',
  EMS_WELCOME = 'EMS-WELCOME',
  RESHARE_REQUESTED = 'RESHARE-REQUESTED',
  PERSONALIZE_EXPERIENCE = 'PERSONALIZE-EXPERIENCE',
  GOT_THERAPIST = 'GOT-THERAPIST',
  SHARE = 'SHARE',
  REMINDER = 'REMINDER',
  EMS = 'EMS',
  //cards
  DISCOVER_COGNI = 'DISCOVER-COGNI',
  DISCOVER_DASHBOARD = 'DISCOVER-DASHBOARD'
}

export enum AppNotificationStatusesEnum {
  NEW = 'NEW',
  SEEN = 'SEEN',
  ACTION_PENDING = 'ACTION-PENDING'
}

//lowercase to match Capacitor codes
export enum PushPermissionStatusesEnum {
  PENDING = 'pending',
  GRANTED = 'granted',
  DENIED = 'denied'
}

//lowercase to match Capacitor codes
export enum ReminderPermissionStatusesEnum {
  PENDING = 'pending',
  GRANTED = 'granted',
  DENIED = 'denied',
  DISABLED = 'disabled',
  SCHEDULED = 'scheduled'
}

export enum ReattemptFrequenciesEnum {
  RETRY_IN_7_DAYS = 'RETRY-IN-7-DAYS',
  RETRY_IN_14_DAYS = 'RETRY-IN-14-DAYS',
  RETRY_IN_28_DAYS = 'RETRY-IN-28-DAYS',
  RETRY_IN_56_DAYS = 'RETRY-IN-56-DAYS',
  RETRY_IN_112_DAYS = 'RETRY-IN-112-DAYS'
}

export enum PushNotificationStatuses {
  SENT = 'SENT',
  CLICKED = 'CLICKED',
  ACTION_TAKEN = 'ACTION-TAKEN',
  DISCARDED = 'DISCARDED'
}

export enum PushNotificationDiscardReasonsEnum {
  APP_FOREGROUND = 'APP-FOREGROUND',
  EXPIRED = 'EXPIRED',
  REPLACED = 'REPLACED',
  UNSUPPORTED = 'UNSUPPORTED'
}

export enum AppMenuNotificationStatusesEnum {
  NEW = 'NEW',
  SEEN = 'SEEN',
  ACTION_PENDING = 'ACTION-PENDING'
}
