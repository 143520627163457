import { Buffer } from 'buffer'

import { AbridgedGenderCodesEnum, GenderCodesEnum } from '..'

export const convertFromGenderToAbridgedGender = (
  gender: GenderCodesEnum
): AbridgedGenderCodesEnum => {
  switch (gender) {
    case GenderCodesEnum.FEMALE:
      return AbridgedGenderCodesEnum.FEMALE
    case GenderCodesEnum.MALE:
      return AbridgedGenderCodesEnum.MALE
    case GenderCodesEnum.UNKNOWN:
      return AbridgedGenderCodesEnum.UNKNOWN
  }
}

export const convertFromAbridgedGenderToGender = (
  gender: AbridgedGenderCodesEnum
): GenderCodesEnum => {
  switch (gender) {
    case AbridgedGenderCodesEnum.FEMALE:
      return GenderCodesEnum.FEMALE
    case AbridgedGenderCodesEnum.MALE:
      return GenderCodesEnum.MALE
    case AbridgedGenderCodesEnum.UNKNOWN:
      return GenderCodesEnum.UNKNOWN
  }
}

export const fromBinarytoBase64 = (input: string) =>
  Buffer.from(input, 'binary').toString('base64')

export const fromBase64toBinary = (input: string) =>
  Buffer.from(input, 'base64').toString('binary')
