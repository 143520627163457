import { EmailEventDocumentDataType, GenderCodesEnum } from '../..'
import {
  ArchiveReasonsEnum,
  DepartmentStatusesEnum,
  EmployeeStatusesEnum,
  InterconnectionStatusesEnum
} from '..'
import { AccessRequestStatusesEnum, DepartmentEmailInvitationStatusesEnum } from '../enums'

export type BatchInvitationType = {
  name: string
  email: string
}

export type DepartmentInterconnection = {
  id?: string
  startedAtDate?: Date
  terminatedAtDate?: Date
  status: InterconnectionStatusesEnum
  departmentId?: string
  employeeId?: string
  corporationId?: string
}

export type DepartmentEmailInvitationDocumentDataType = EmailEventDocumentDataType & {
  name: string
  createdAtDate: Date
  departmentId: string
  corporationId: string
  uid: string
  status: DepartmentEmailInvitationStatusesEnum
}

export type DepartmentAccessRequestDocumentDataType = {
  accessRequestId: string
  cognicode?: string
  createdAtDate: Date
  updatedAtDate?: Date
  seenAtDate?: Date
  departmentId: string
  corporationId: string
  departmentName: string
  employeeId: string
  employeeFirstName: string
  employeeLastName: string
  employeeGender: GenderCodesEnum
  status: AccessRequestStatusesEnum
}

export type EmployeeDocumentDataType = {
  uid?: string
  createdAtDate?: Date
  updatedAtDate?: Date
  firstName?: string
  lastName?: string
  gender?: GenderCodesEnum
  status?: EmployeeStatusesEnum
  archiveReason?: ArchiveReasonsEnum
  interconnectionId?: string
}

export type DepartmentDocumentDataType = {
  departmentId?: string
  corporationId?: string
  cognicode?: string
  createdAtDate?: Date
  updatedAtDate?: Date
  departmentName?: string
  status?: DepartmentStatusesEnum
  interconnectionId?: string
}

export type AvailableDepartmentDocumentDataType = {
  c: string // cogni code
  d: string // date of inclusion in ISOString format
  du: string // date of update in ISOString format -- if du = d, it's an insertion, otherwise it's an update
  dd?: string // date of deletion in ISOString format - therapist is no longer available and should not be displayed in the app (TODO old deletions can be removed from the database)
  n: string // department name
  pp?: boolean // if true - profile picture is available
  ppi?: string // profile picture image
}
