import {
  GenderCodesEnum,
  LanguageCodesEnum,
  MobilePhoneNumber,
  ProductCodesEnum,
  SexCodesEnum,
  TaxPayerId
} from '../..'

import {
  AffiliationCodesEnum,
  AppOnboardingCodesEnum,
  CorporateUserTypesEnum,
  DashboardSubscriptionTypesEnum,
  ProfessionalId,
  ProfessionalInformation,
  PsyeduSubscriptionTypesEnum
} from '..'

export type Account = {
  uid: string
  email: string
  createdAtDate: Date
  accountDeleted: boolean
  language: LanguageCodesEnum
  signupSource: ProductCodesEnum
  affiliation: AffiliationCodesEnum
  appOnboarding: AppOnboardingCodesEnum
  dashboardSubscriptionType: DashboardSubscriptionTypesEnum
  psyeduSubscriptionType: PsyeduSubscriptionTypesEnum
  corporateUserType: CorporateUserTypesEnum
  appTermsVersionAccepted?: string
  dashTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  appTermsVersionAcceptedAtDate?: Date
  dashTermsVersionAcceptedAtDate?: Date
  privacyPolicyVersionAcceptedAtDate?: Date
  formatVersion: number

  /**
   * For corporate users and superusers only
   */
  corpDashTermsVersionAccepted?: string
  corpDashTermsVersionAcceptedAtDate?: Date
}

export type User = Account & {
  firstName: string
  lastName: string
  updatedAtDate: Date
  lastSignedInAtDate: Date

  cognicode?: string
  birthday?: string
  gender?: GenderCodesEnum
  professionalInformation?: ProfessionalInformation
  professionalId?: ProfessionalId
  mobilePhoneNumber?: MobilePhoneNumber
  appUpdatedAtDate?: Date
  dashUpdatedAtDate?: Date
  appLastSignedInAtDate?: Date
  dashLastSignedInAtDate?: Date
  accountDeletedAtDate?: Date
  hasTherapist?: boolean
  reconfirmationRequired?: boolean
  taxPayerId?: TaxPayerId
  collegeStudent?: boolean
  sex?: SexCodesEnum
  /**
   * For corporate users only (department)
   */
  departmentId?: string
  /**
   * For corporate users and superusers only
   */
  corporationId?: string
  corpDashUpdatedAtDate?: Date
  corpDashLastSignedInAtDate?: Date

  // For temporaty usage (during migration) - TODO remove as soon as possible
  oc?: number
  tte?: string
  ttn?: string
  ttuid?: string
}

/**
 * For attribute name validation when updating user information.
 * To be replaced by newer formats.
 */
export type AllUserFieldsOptional = {
  uid?: string
  email?: string
  cognicode?: string
  affiliation?: AffiliationCodesEnum
  appOnboarding?: number
  updatedAtDate?: Date
  lastSignedInAtDate?: Date
  firstName?: string
  lastName?: string
  gender?: GenderCodesEnum
  language?: LanguageCodesEnum
  formatVersion?: number
  birthday?: string
  professionalInformation?: ProfessionalInformation
  professionalId?: ProfessionalId
  mobilePhoneNumber?: MobilePhoneNumber
  appUpdatedAtDate?: Date
  dashUpdatedAtDate?: Date
  appLastSignedInAtDate?: Date
  dashLastSignedInAtDate?: Date
  appTermsVersionAccepted?: string
  dashTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  appTermsVersionAcceptedAtDate?: Date
  dashTermsVersionAcceptedAtDate?: Date
  privacyPolicyVersionAcceptedAtDate?: Date
  hasTherapist?: boolean
  reconfirmationRequired?: boolean
  dashboardSubscriptionType?: DashboardSubscriptionTypesEnum
  psyeduSubscriptionType?: PsyeduSubscriptionTypesEnum
  accountDeleted?: boolean
  accountDeletedAtDate?: Date
  collegeStudent?: boolean
  sex?: SexCodesEnum
  taxPayerId?: TaxPayerId
  corporateUserType?: CorporateUserTypesEnum
  /**
   * For corporate users only
   */
  departmentId?: string
  /**
   * For corporate users and superusers only
   */
  corporationId?: string
  corpDashTermsVersionAccepted?: string
  corpDashTermsVersionAcceptedAtDate?: Date
  corpDashUpdatedAtDate?: Date
  corpDashLastSignedInAtDate?: Date

  tte?: string
  ttn?: string
  ttuid?: string
}

export type AppUserView = {
  uid?: string
  email?: string
  language?: LanguageCodesEnum
  formatVersion?: number
  appTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  firstName?: string
  lastName?: string
  birthday?: string
  gender?: GenderCodesEnum
  appOnboarding?: number
  appUpdatedAtDate?: Date
  minicardIds?: string[]
  /** @deprecated */
  isTherapist?: boolean // TODO remove after app 3.1.2 has been launched
  /** @deprecated */
  hasTherapist?: boolean // TODO remove after app 3.1.2 has been launched
  reconfirmationRequired?: boolean
  collegeStudent?: boolean
  sex?: SexCodesEnum
  psyeduSubscriptionType?: PsyeduSubscriptionTypesEnum
}

export type DashUserView = {
  uid?: string
  email?: string
  language?: LanguageCodesEnum
  formatVersion?: number
  dashTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  cognicode?: string
  affiliation?: AffiliationCodesEnum
  gender?: GenderCodesEnum
  firstName?: string
  lastName?: string
  dashUpdatedAtDate?: Date
  dashboardSubscriptionType?: DashboardSubscriptionTypesEnum
  mobilePhoneNumber?: MobilePhoneNumber
  professionalInformation?: ProfessionalInformation
  professionalId?: ProfessionalId
}

export type SupportUserView = {
  uid?: string
  affiliation?: AffiliationCodesEnum
  cognicode?: string
  email?: string
  firstName?: string
  lastName?: string
  language?: LanguageCodesEnum
  gender?: GenderCodesEnum
  mobilePhoneNumber?: string
  profession?: string
  professionalId?: ProfessionalId
  dashboardSubscriptionType?: DashboardSubscriptionTypesEnum
  psyeduSubscriptionType?: PsyeduSubscriptionTypesEnum
  updatedAtDate: Date
}
