import { SexCodesEnum } from '../..'

import {
  PsyeduSubscriptionTypesEnum,
  SubscriberTypesEnum,
  SubscriptionStatusesEnum
} from '..'
import { PsychoeducationEnrollmentStatusesEnum, ScaleSetsEnum } from '../enums'

export type ModuleIds = number[]
export type ScaleIds = string[]

export type IndividualPsychoeducationEnrollment = {
  uid: string
  psychoeducationSubscriptionId: string
  subscriberType: SubscriberTypesEnum
  psyeduSubscriptionType: PsyeduSubscriptionTypesEnum
  enrollmentStatus: PsychoeducationEnrollmentStatusesEnum
  subscriptionStatus: SubscriptionStatusesEnum
  expiresAtDate?: Date
  pendingModules: ModuleIds
  lastUpdatedAtDate: Date
}

export type CorporatePsychoeducationEnrollment =
  IndividualPsychoeducationEnrollment & {
    departmentId?: string
    corporationId?: string
  }

export type PsychoeducationEnrollment =
  | IndividualPsychoeducationEnrollment
  | CorporatePsychoeducationEnrollment

export type PsychoeducationModuleControlItems = {
  enrollmentStatus?: PsychoeducationEnrollmentStatusesEnum
  pendingModules: ModuleIds
  completedModules: ModuleIds
  lastModuleCompleted: number
  lastModuleCompletedAtDate: Date
  practiceTimeExpiresAtDate?: Date
  lastUpdatedAtDate: Date
}

export type PsychoeducationScaleControlItems = {
  enrollmentStatus?: PsychoeducationEnrollmentStatusesEnum
  scaleSet: ScaleSetsEnum
  nextScales: ScaleIds
  requireScaleBegginingAtDate: Date
  lastScaleCompletedAtDate?: Date
  lastUpdatedAtDate: Date
}

export type PsychoeducationControlItems = PsychoeducationModuleControlItems &
  PsychoeducationScaleControlItems

export type PsychoeducationProfile = {
  birthday: string
  sex: SexCodesEnum
  collegeStudent: boolean
}
