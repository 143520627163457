export enum ProfessionCodesEnum {
  PSYCHIATRY = 'PSYCHIATRY',
  PSYCHOLOGY = 'PSYCHOLOGY',
  NEUROLOGY = 'NEUROLOGY',
  NEUROPSYCHIATRY = 'NEUROPSYCHIATRY',
  NEUROPSYCHOLOGY = 'NEUROPSYCHOLOGY',
  COACHING = 'COACHING',
  OTHER = 'OTHER',
  UNKNOWN = 'unknown'
}

export enum ProfessionalIdTypesEnum {
  BRAZIL_CRM = 'CRM'
}

export enum AffiliationCodesEnum {
  EMS = 'EMS',
  NONE = 'none'
}

export enum CognitiveDistortionsEnum {
  ALL_OR_NOTHING = 'ALL_OR_NOTHING',
  CATASTROPHIZING = 'CATASTROPHIZING',
  DISCOUNTING_THE_POSITIVE = 'DISCOUNTING_THE_POSITIVE',
  EMOTIONAL_REASONING = 'EMOTIONAL_REASONING',
  LABELING = 'LABELING',
  MAGNIFICATION_VS_MINIMIZATION = 'MAGNIFICATION_VS_MINIMIZATION',
  MENTAL_FILTERING = 'MENTAL_FILTERING',
  MIND_READING = 'MIND_READING',
  OVERGENERALIZATION = 'OVERGENERALIZATION',
  PERSONALIZATION = 'PERSONALIZATION',
  SHOULD_HAVE_STATEMENTS = 'SHOULD_HAVE_STATEMENTS',
  TUNNEL_VISION = 'TUNNEL_VISION'
}

export enum InterconnectionStatusesEnum {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED'
}

export enum PatientStatusesEnum {
  ACTIVE = 'ACTIVE',
  RECONFIRMATION_PENDING = 'RECONFIRMATION_PENDING',
  ARCHIVED = 'ARCHIVED'
}

export enum EmployeeStatusesEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export enum SourcePrioritiesEnum {
  SUICIDE_ALERT = 9,
  NEW_EVENT = 6,
  ACTIVE = 3,
  NONE = 0
}

export enum ArchiveReasonsEnum {
  THERAPIST = 'therapist',
  NON_EMS_PATIENT = 'non_ems_patient',
  PATIENT = 'patient',
  DEPARTMENT = 'department',
  EMPLOYEE = 'employee'
}

export enum TherapistStatusesEnum {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED', // when therapist deleted the account
  ARCHIVED_BY_THERAPIST = 'ARCHIVED_BY_THERAPIST'
}

export enum DepartmentStatusesEnum {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED', // when subscription is expired
  ARCHIVED_BY_DEPARTMENT = 'ARCHIVED_BY_DEPARTMENT'
}

export enum InvitationStatusesEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED'
}

export enum AccessRequestStatusesEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED'
}

export enum ProfessionCodeNumbersEnum {
  PSYCHIATRY = 1,
  PSYCHOLOGY = 2,
  NEUROLOGY = 3,
  NEUROPSYCHIATRY = 4,
  NEUROPSYCHOLOGY = 5,
  COACHING = 6,
  OTHER = 98,
  UNKNOWN = 0
}

export enum MembershipStatusesEnum {
  MEMBER = 'MEMBER',
  NOT_A_MEMBER = 'NOT-A-MEMBER'
}

export enum ProductSubscriptionCodesEnum {
  COGNI_DASHBOARD = 'cogni-dashboard',
  COGNI_CORP_PSYEDU = 'cogni-corp-psyedu',
  COGNI_PSYEDU = 'cogni-psyedu'
}

export enum PsyeduSubscriptionTypesEnum {
  NONE = 'NONE',
  FREE = 'FREE',
  TRIAL = 'TRIAL',
  PILOT = 'PILOT',
  BASIC = 'BASIC'
}

export enum SubscriberTypesEnum {
  CORPORATE = 'CORPORATE',
  INDIVIDUAL = 'INDIVIDUAL',
  NONE = 'NONE'
}

export enum SubscriptionStatusesEnum {
  ACTIVE = 'ACTIVE', // OK
  SUSPENDED = 'SUSPENDED', // payment failed
  BLOCKED = 'BLOCKED', // payment failed too many times
  EXPIRED = 'EXPIRED', // pre-payed psyedu
  CANCELLED = 'CANCELLED', // cancelled, pending last payment
  TERMINATED = 'TERMINATED' // no pending payments
}

export enum DashboardSubscriptionTypesEnum {
  TRIAL = 'TRIAL',
  FORMER_FREEMIUM = 'FORMER-FREEMIUM',
  FREEMIUM = 'FREEMIUM',
  BASIC = 'BASIC',
  NONE = 'NONE'
}

export enum PatientNotificationTypesEnum {
  SUICIDE_ALERT = 'suicide-alert',
  INVITATION_RECEIVED = 'invitation-received',
  PATIENT_UNSHARED = 'patient-unshared',
  DTR = 'dtr'
}

export enum CorporateUserTypesEnum {
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPEADMIN',
  NONE = 'NONE'
}

export enum AppOnboardingCodesEnum {
  NOT_INITIATED = 0,
  FIRST_DTR_CREATED = 2
}

export enum ScalesIdsEnum {
  PANAS = 'panas',
  ESV = 'esv',
  EAR = 'ear'
}

export enum ScaleSetsEnum {
  INITIAL = 'INITIAL',
  PANAS = 'PANAS',
  ESV_AND_EAR = 'ESV-AND-EAR'
}

export enum PsychoeducationEnrollmentStatusesEnum {
  REGISTERED = 'REGISTERED',
  ENROLLED = 'ENROLLED',
  ONBOARDED = 'ONBOARDED'
}

export enum DepartmentEmailInvitationStatusesEnum {
  PENDING = 'PENDING',
  SENT_TO_SENDGRID = 'SENT_TO_SENDGRID'
}
