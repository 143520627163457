export const delay = (milliseconds: number) =>
  new Promise(resolve => setTimeout(resolve, milliseconds))

export const getDaysInFuture = (daysInFuture: number) => {
  const aDayInTheFuture = new Date()
  aDayInTheFuture.setDate(aDayInTheFuture.getDate() + daysInFuture)
  return aDayInTheFuture
}

export const getDaysInFutureStartingAt = (daysInFuture: number, date: Date) => {
  date.setDate(date.getDate() + daysInFuture)
  return date
}

export const getHoursInFuture = (hoursInFuture: number) => {
  const aTimeInTheFuture = new Date()
  aTimeInTheFuture.setMinutes(aTimeInTheFuture.getHours() + hoursInFuture)
  return aTimeInTheFuture
}

export const getMinutesInFuture = (minutesInFuture: number) => {
  const aTimeInTheFuture = new Date()
  aTimeInTheFuture.setMinutes(aTimeInTheFuture.getMinutes() + minutesInFuture)
  return aTimeInTheFuture
}

export const getSecondsInFuture = (secondsInFuture: number) => {
  const aTimeInTheFuture = new Date()
  aTimeInTheFuture.setSeconds(aTimeInTheFuture.getSeconds() + secondsInFuture)
  return aTimeInTheFuture
}

export const isDateInPast = (date: Date) => {
  const currentDate = new Date()
  if (date.toISOString() < currentDate.toISOString()) {
    return true
  }
  return false
}

export const elapsedHours = (date: Date) => {
  const today = new Date()
  const diff = Math.abs(today.getTime() - date.getTime())
  return Math.ceil(diff / (1000 * 3600))
}

export const yearsFromBirthdate = (birthdate: string) => {
  const today = new Date()
  const date = new Date(birthdate)
  let ageAux = today.getFullYear() - date.getFullYear()
  const month = today.getMonth() - date.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
    ageAux--
  }
  return ageAux
}

export const getDateFromEpochTimestampInSeconds = (
  epochTimestampInSeconds: number
) => {
  return new Date(epochTimestampInSeconds * 1000)
}

export const getDateFromEpochTimestampInMilliseconds = (
  epochTimestampInMilliseconds: number
) => {
  return new Date(epochTimestampInMilliseconds)
}
