import { ProfessionCodesEnum } from '..'

export type CogniAppSignupQuiz = {
  uid: string
  patient: boolean
  healthCareStudent: boolean
  healthCareProfessional: boolean
  other: boolean
  profession: ProfessionCodesEnum
  otherInterest?: string
  graduationYear?: number
  updatedAtDate: Date
}
