export enum GroupsEnum {
  COGNI = 'cogni',
  COMPANY = 'company'
}

export enum EducationLevelCodesEnum {
  UNDERGRADUATE = 'UNDERGRADUATE',
  GRADUATE = 'GRADUATE',
  POSTGRADUATE = 'POSTGRADUATE',
  DOCTORATE = 'DOCTORATE',
  OTHER = 'OTHER',
  UNKNOWN = 'unknown'
}

// lowercase to match OS/browser locales
export enum LanguageCodesEnum {
  PT_BR = 'pt-BR',
  EN_US = 'en-US',
  /**
   * For compatibility only. DO NOT use for new users.
   */
  UNKNOWN = 'unknown'
}

// lowercase for historical reasons
export enum ProductCodesEnum {
  COGNI_APP = 'cogni-app',
  COGNI_DASHBOARD = 'cogni-dashboard',
  COGNI_CORP_DASHBOARD = 'cogni-corp-dashboard',
  COGNI_PSYEDU = 'cogni-psyedu',
  COGNI_EMS = 'cogni-ems',
  WEBSITE = 'website'
}

/**
 * Refers to biological and physiological characteristics.
 */
export enum SexCodesEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

/**
 * Encompasses a person's identities, expressions, and societal roles.
 * In Cogni, gender is used for localization purposes, according to
 * user's answer to the question 'how do you want to be referred to?'.
 */
export enum GenderCodesEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNKNOWN = 'unknown'
}

/**
 * Encompasses a person's identities, expressions, and societal roles.
 * In Cogni, gender is used for localization purposes, according to
 * user's answer to the question 'how do you want to be referred to?'.
 * For use by the AvailableTherapist list
 */
export enum AbridgedGenderCodesEnum {
  FEMALE = 'F',
  MALE = 'M',
  UNKNOWN = 'U'
}

export enum MessageFormatsEnum {
  DATA = 'DATA',
  NOTIFICATION = 'NOTIFICATION'
}

export enum MessageTypesEnum {
  OTC = 'otc'
}

export enum MessageMethodsEnum {
  SMS = 'sms',
  WHATSAPP = 'whatsapp'
}

export enum ActionLinkModesEnum {
  VERIFY_EMAIL = 'verifyEmail',
  RESET_PASSWORD = 'resetPassword',
  SIGN_IN = 'signIn',
  CUSTOM_SIGN_IN = 'customSignIn'
}

export enum ConnectionStatusesEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED'
}

export enum PlatformsEnum {
  IOS = 'IOS',
  ANDROID = 'ANDROID'
}

export enum AuthStatusesEnum {
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN = 'UNKNOWN'
}

export enum TaxPayerIdsEnum {
  CNPJ = 'cnpj',
  CPF = 'cpf'
}

export enum EmailProviderCodesEnum {
  SENDGRID = 'SENDGRID'
}

// lowercase to match Sendgrid codes
export enum EventTypesEnum {
  QUEUED = 'queued', // TODO do we need this?
  PROCESSED = 'processed',
  DEFERRED = 'deferred',
  DELIVERED = 'delivered',
  OPEN = 'open',
  CLICK = 'click',
  BOUNCE = 'bounce',
  DROPPED = 'dropped',
  SPAMREPORT = 'spamreport',
  UNSUBSCRIBE = 'unsubscribe'
  //GROUP_UNSUBSCRIBE = 'group_unsubscribe',
  //GROUP_RESUBSCRIBE = 'group_resubscribe'
}
