import { BaseResponse } from '../..'
import { CreateUserResponse, GetUserViewResponse, SendMessageResponse, UpdateUserProfileResponse, VerifyEMSMembershipResponse } from './firebase-interop-authenticated-requests'
import { CreateAccountResponse, GetHttpRedirectionResponse, ValidateCustomActionLinkResponse } from './firebase-interop-unauthenticated-requests'

export type DatabaseResponse =
  | BaseResponse
  | CreateAccountResponse
  | GetUserViewResponse
  | UpdateUserProfileResponse
  | SendMessageResponse
  | CreateUserResponse
  | VerifyEMSMembershipResponse
  | GetHttpRedirectionResponse
  | ValidateCustomActionLinkResponse
