import {
  AbridgedGenderCodesEnum,
  GenderCodesEnum
} from '../..'

import {
  AffiliationCodesEnum,
  ArchiveReasonsEnum,
  InterconnectionStatusesEnum,
  InvitationStatusesEnum,
  PatientStatusesEnum,
  ProfessionalIdTypesEnum,
  ProfessionCodeNumbersEnum,
  ProfessionCodesEnum,
  SourcePrioritiesEnum,
  TherapistStatusesEnum
} from '..'

export type Interconnection = {
  id?: string
  startedAtDate?: Date
  terminatedAtDate?: Date
  status: InterconnectionStatusesEnum
  therapistId?: string
  patientId?: string
}

export type Invitation = {
  invitationId: string
  affiliation?: AffiliationCodesEnum
  /** @deprecated */
  cogniCode?: string // TODO remove after app 3.1.2 has been launched
  cognicode?: string
  createdAtDate: Date
  updatedAtDate?: Date
  seenAtDate?: Date
  therapistId: string
  therapistName: string // TODO Remove
  therapistFirstName: string
  therapistLastName: string
  therapistGender: GenderCodesEnum
  professionalId?: string
  professionalIdType?: ProfessionalIdTypesEnum
  profession: ProfessionCodesEnum
  patientId: string
  patientFirstName: string
  patientLastName: string
  patientGender: GenderCodesEnum
  status: InvitationStatusesEnum
}

export type Patient = {
  createdAtDate?: Date
  updatedAtDate?: Date
  uid?: string
  firstName?: string
  lastName?: string
  gender?: GenderCodesEnum
  status?: PatientStatusesEnum
  archiveReason?: ArchiveReasonsEnum
  sortPriority?: SourcePrioritiesEnum
  interconnectionId?: string
  affiliation?: AffiliationCodesEnum
}

export type Therapist = {
  cognicode?: string
  affiliation?: AffiliationCodesEnum
  createdAtDate?: Date
  updatedAtDate?: Date
  uid?: string
  /** @deprecated */
  name?: string // TODO remove after app 3.1.2 has been launched
  firstName?: string
  lastName?: string
  gender?: GenderCodesEnum
  professionalId?: string
  professionalIdType?: ProfessionalIdTypesEnum
  profession?: ProfessionCodesEnum
  status?: TherapistStatusesEnum
  interconnectionId?: string
}

export type AvailableTherapist = {
  c: string // cogni code
  d: string // date of inclusion in ISOString format
  du: string // date of update in ISOString format -- if du = d, it's an insertion, otherwise it's an update
  dd?: string // date of deletion in ISOString format - therapist is no longer available and should not be displayed in the app (TODO old deletions can be removed from the database)
  fn: string // first name (or name in case of corporate department)
  ln: string // last name
  g: AbridgedGenderCodesEnum // gender
  p: ProfessionCodeNumbersEnum
  pp?: boolean // if true - profile picture is available
  ppi?: string // profile picture image
  a?: AffiliationCodesEnum
  id?: string // professional Id (CRM number)
  it?: ProfessionalIdTypesEnum
}

export type PendingCloning = {
  therapistId: string
  patientId: string
  createdAtDate: Date
}

export type PendingCollectionCleanup = {
  therapistId: string
  patientId: string
  createdAtDate: Date
}
