import {
  EmailProviderCodesEnum,
  GroupsEnum
} from '../enums'

export type EmailEventDocumentDataType = {
  group: GroupsEnum
  email: string
  id: string
  emailTypeId: string
  provider?: EmailProviderCodesEnum
  uid?: string
  departmentId?: string
  corporationId?: string
  invitationId?: string
  sendgridId?: string
  sentAtDate?: Date
  processedAtDate?: Date
  deferredAtDate?: Date
  deliveredAtDate?: Date
  openAtDate?: Date
  clickedAtDate?: Date
  bouncedAtDate?: Date
  droppedAtDate?: Date
  reportedAtDate?: Date
  unsubscribedAtDate?: Date
  bounce_classification?: string
  reason?: string
  useragent?: string
  url?: string
  attempt?: number
  response?: string
}
