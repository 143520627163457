import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private storage: Storage) { }

  initializeStorage() {
    try {
      return this.storage.create();
    } catch (error) {
      throw error
    }
  }

  saveStringValue(key: string, value: string) {
    return this.write(key, value);
  }

  readStringValue(key: string): Promise<string> {
    return this.read(key);
  }

  saveDateValue(key: string, value: Date) {
    return this.write(key, value);
  }

  readDateValue(key: string): Promise<Date> {
    return this.read(key);
  }

  saveNumberValue(key: string, value: number) {
    return this.write(key, value);
  }

  readNumberValue(key: string): Promise<number> {
    return this.read(key);
  }

  saveBooleanValue(key: string, value: boolean) {
    return this.write(key, value);
  }

  readBooleanValue(key: string): Promise<boolean> {
    return this.read(key);
  }

  saveMap(key: string, attributes: any) {
    return this.write(key, attributes);
  }

  readMap(key: string): Promise<any> {
    return this.read(key);
  }

  removeKey(key: string) {
    return this.storage.remove(key);
  }

  async clearStorage() {
    await this.storage.clear();
  }

  private read(key: string) {
    return this.storage.get(key);
  }

  private write(key: string, value: any) {
    return this.storage.set(key, value);
  }
}

