import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FirebaseInitializer, CommonAuthClient } from '@cogniapp/common-typescript-frontend-lib';
import { CogniFunctionsClient } from 'src/libs';
import { httpLoaderFactory } from './shared/shared.module';

import { LocalStorageService } from './core/services/local-storage.service';
import { environment} from '../environments/environment';
import { LanguageService } from './core/services/language.service';
import { AppConfigService } from './core/services/app-config.service';

const initializeApp = (
  firebaseInitializer: FirebaseInitializer, 
  localStorageService: LocalStorageService, 
  languageService: LanguageService) => {
  return async () => { 
    firebaseInitializer.initializeInstances(environment.firebase, false);
    localStorageService.initializeStorage();
    await languageService.setInitialLanguage();
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot({}),
    IonicStorageModule.forRoot({
      storeName: 'cogniapp'
    }),
    AppRoutingModule
  ],
  providers: [
    FirebaseInitializer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, deps: [FirebaseInitializer, LocalStorageService, LanguageService ], useFactory: initializeApp, multi: true },
    { provide: CommonAuthClient, deps: [FirebaseInitializer] },
    { provide: CogniFunctionsClient, deps: [FirebaseInitializer] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
