import {
  alphabeticRegex,
  alphanumericRegex,
  extendedAlphanumericRegex,
  numericRegex
} from './regular-expressions'

export const isNumeric = (value: string): boolean => {
  return numericRegex.test(value)
}

export const isAlphabetic = (value: string): boolean => {
  if (value === null || value === undefined) {
    return false
  }
  return alphabeticRegex.test(value)
}

export const isAlphanumeric = (value: string): boolean => {
  if (value === null || value === undefined) {
    return false
  }
  return alphanumericRegex.test(value)
}

export const isExtendedAlphanumeric = (value: string): boolean => {
  if (value === null || value === undefined) {
    return false
  }
  return extendedAlphanumericRegex.test(value)
}
