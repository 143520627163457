import { CommonFirestoreClient, FirebaseInitializer, fromTimestampToDateByDocument } from '@cogniapp/common-typescript-frontend-lib'
import { formatTimestampAsKey } from 'src/libs'
import { CollectionReference, DocumentData } from 'firebase/firestore'

const usersCollId = '/users'
const appUserViewsCollId = '/appUserViews'
const dashUserViewsCollId = '/dashUserViews'

const debugLogsCollId = '/debugLogs'
const eventLogsCollId = '/eventLogs'
const interconnectionsCollId = '/interconnections'
const invitationsRequestsCollId = '/invitations'
const patientRecordsCollId = '/patientRecords'
const psyeduSubscribersCollId = '/psyeduSubscribers'
const quizzesCollId = '/quizzes'
const recordsCollId = '/records'
const supportCollId = '/support'

const departmentsId = 'departments'
const ndtrsId = 'ndtrs'
const elogs = 'elogs'
const patientRecordsId = 'patndtrs'
const patientsId = 'patients'
const therapistsId = 'therapists'
const ticketsId = 'tickets'
const scaleResultsId = 'scaleResults'
const historyItemsId = 'historyItems'
const underEditionId = 'underEdition'


// collection groups MUST NOT contain a leading '/'
const departmentsCollGroupId = 'departments'
const emailEventsCollGroupId = 'emailEvents'
const employeesCollGroupId = 'employees'
const patientsCollGroupId = 'patients'
const therapistsCollGroupId = 'therapists'


export class CogniFirestoreClient extends CommonFirestoreClient {
  
  constructor(firebaseInitializer: FirebaseInitializer, instanceName: string, private appNameForLog: string) {
    super(firebaseInitializer, instanceName)
  }

  async getAppUserView(uid: string) {
    try {
      const view = await this.getDoc(this.docRef(this.getAppUserViewsCollRef(), uid));
      if (view) {
        fromTimestampToDateByDocument(view, 'AtDate');
        return view;
      }
    } catch(ex) {
      // TODO perhaps we should throw the exception
      // no problem, it does not exit in the databse
    }
    return undefined;
  }

  async getDashUserView(uid: string) {
    try {
      const view = await this.getDoc(this.docRef(this.getDashUserViewsCollRef(), uid));
      if (view) {
        fromTimestampToDateByDocument(view, 'AtDate');
        return view;
      }
    } catch(ex) {
      // TODO perhaps we should throw the exception
      // no problem, it does not exit in the databse
    }
    return undefined;
  }

  getEventLogReference(uid: string, key: string) {
    return this.docRef(this.getEventLogsCollRef(uid), key)
  }

  getUserReference(uid: string) {
    return this.docRef(this.getUsersCollRef(), uid)
  }

  getAppUserViewReference(uid: string) {
    return this.docRef(this.getAppUserViewsCollRef(), uid)
  }

  getDashUserViewsReference(uid: string) {
    return this.docRef(this.getDashUserViewsCollRef(), uid)
  }

  // TODO check
  insertEventLog(uid: string, key: string, attributes: DocumentData) {
    return this.docRef(this.getEventLogsCollRef(uid), key)
  }

  getSomeonesTherapistsCollRef(uid: string): CollectionReference {
    return this.collRef(`${usersCollId}/${uid}/${therapistsId}`)
  }

  getSomeonesPatientsCollRef(uid: string): CollectionReference {
    return this.collRef(`${usersCollId}/${uid}/${patientsId}`)
  }

  getSomeonesDepartmentsCollRef(uid: string): CollectionReference {
    return this.collRef(`${usersCollId}/${uid}/${departmentsId}`)
  }

  getPatientsDTRsCollRef(therapistId: string, patientId: string): CollectionReference {
    return this.collRef(
      `${patientRecordsCollId}/${therapistId}/${patientsId}/${patientId}/${patientRecordsId}`
    )
  }

  insertEndUserSupportTicket(uid: string, key: string, attributes: any) {
    return this.insertDoc(this.getEndUserTicketsCollRef(uid), key, attributes);
  }

  logDebug(uid: string, details: any) {
    const attributes = { l: 1, d: details, s: 1 };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  logInfo(uid: string, details: any) {
    const attributes = { l: 2, d: details, s: 1 };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  logWarning(uid: string, details: any) {
    const attributes = { l: 3, d: details, s: 1 };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  logError(uid: string, details: any) {
    const attributes = { l: 4, d: details, s: 1 };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  logCritical(uid: string, details: any) {
    const attributes = { l: 5, d: details, s: 1 };
    return this.insertDebugLog(uid, this.appNameForLog, attributes);
  }

  protected getUsersCollRef(): CollectionReference {
    return this.collRef(usersCollId)
  }

  protected getAppUserViewsCollRef(): CollectionReference {
    return this.collRef(appUserViewsCollId)
  }

  protected getDashUserViewsCollRef(): CollectionReference {
    return this.collRef(dashUserViewsCollId);
  }
  
  protected getInterconnectionsCollRef(): CollectionReference {
    return this.collRef(interconnectionsCollId)
  }

  protected getInvitationsCollRef(): CollectionReference {
    return this.collRef(invitationsRequestsCollId)
  }

  protected getSomeonesDTRsCollRef(uid: string): CollectionReference {
    return this.collRef(`${recordsCollId}/${uid}/${ndtrsId}`)
  }

  // TODO check
  protected getEMSTicketsCollRef(): CollectionReference {
    return this.collRef(`${supportCollId}/ems/contactRequests`)
  }

  // TODO check
  protected getMiniQuizCollRef(): CollectionReference {
    return this.collRef(`/${quizzesCollId}/cogniapp/miniQuizAnswers`)
  }

  protected getPsychoeducationSubscribersCollRef(): CollectionReference {
    return this.collRef(psyeduSubscribersCollId)
  }

  protected getPsychoeducationSubscribersReference(uid: string) {
    return this.docRef(this.getPsychoeducationSubscribersCollRef(), uid)
  }

  protected getPsychoeducationScaleResultsCollRef(uid: string): CollectionReference {
    return this.collRef(`${psyeduSubscribersCollId}/${uid}/${scaleResultsId}`)
  }

  protected getPsychoeducationHistoryItemsCollRef(uid: string): CollectionReference {
    return this.collRef(`${psyeduSubscribersCollId}/${uid}/${historyItemsId}`)
  }

  protected getSomeonesDTRsUnderEditionCollRef(uid: string): CollectionReference {
    return this.collRef(`${recordsCollId}/${uid}/${underEditionId}`)
  }

  // TODO check
  protected getSomeonesAppDevicesCollRef(uid: string): CollectionReference {
    return this.collRef(`${usersCollId}/${uid}/cogniAppDevices`)
  }
  
  // TODO check
  protected getSomeonesAppPushConfigurationCollRef(uid: string): CollectionReference {
    return this.collRef(`${usersCollId}/${uid}/cogniAppPushConfiguration`)
  }

  // TODO check
  protected getSomeonesAppRemindersConfigurationCollRef(uid: string): CollectionReference {
    return this.collRef(`${usersCollId}/${uid}/cogniAppRemindersConfiguration`)
  }

  // TODO check
  protected getSomeonesAppPushesCollRef(uid: string): CollectionReference {
    return this.collRef(`${usersCollId}/${uid}/cogniAppPushes`)
  }

  protected getEndUserTicketsCollRef(uid: string): CollectionReference {
    return this.collRef(`${supportCollId}/${uid}/${ticketsId}`)
  }

  protected getEventLogsCollRef(uid: string): CollectionReference {
    return this.collRef(`${eventLogsCollId}/${uid}/${elogs}`)
  }

  private insertDebugLog(uid: string, appNameForLog: string, attributes: any) {
    const key = formatTimestampAsKey(new Date())
    return this.insertDoc(
      this.getDebugLogsCollRef(uid, appNameForLog),
      key,
      attributes
    )
  }

  private getDebugLogsCollRef(
    uid: string,
    appNameForLog: string
  ): CollectionReference {
    return this.collRef(`${debugLogsCollId}/${uid}/${appNameForLog}Logs`)
  }
}
