import {
  ProductSubscriptionCodesEnum,
  PsyeduSubscriptionTypesEnum,
  SubscriberTypesEnum,
  SubscriptionStatusesEnum
} from '..'

export type BaseSubscription = {
  id: string
  startedAtDate: Date
  subscriberType: SubscriberTypesEnum
  status: SubscriptionStatusesEnum
  product: ProductSubscriptionCodesEnum
}

export type PsychoeducationSubscription = BaseSubscription & {
  departmentId?: string
  corporationId?: string
  psyeduSubscriptionType: PsyeduSubscriptionTypesEnum
  expiresAtDate?: Date
}

export type CorporatePsychoeducationSubscription = BaseSubscription & {
  departmentId?: string
  corporationId?: string
  psyeduSubscriptionType: PsyeduSubscriptionTypesEnum
  expiresAtDate?: Date
}

export type Subscription =
  | PsychoeducationSubscription
  | CorporatePsychoeducationSubscription
