import { assertNonNullish } from './assertion'

export const formatTimestampAsKey = (date: Date): string => {
  assertNonNullish(date, 'formatTimestampAsKey', 'date')
  return date.toISOString().replace(/[-.:]/g, '')
}

export const formatISOStringAsKey = (date: string): string =>
  date.replace(/[-.:]/g, '')

export const capitalizeFirstLetter = (text: string): string => {
  //TODO transformar tudo primeiro para letra minúscula e depois deixar só a primeira letra de cada
  // palavra em maiúscula
  if (text && text.length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  return text
}

export const formatBirthdate = (date: Date): string => {
  assertNonNullish(date, 'formatBirthdate', 'date')
  const day = date.getUTCDate().toString().padStart(2, '0')
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = date.getUTCFullYear()
  return `${year}-${month}-${day}`
}

export const yearForCopyright = (): string => {
  const now = new Date()
  return `${now.getUTCFullYear()}`
}

export const formatPushIdAsKey = (id: string): string => id.replace(/[:@]/g, '')

export const fromISOStringToDateByDocument = (
  document: Record<string, unknown>,
  pattern: string
) => {
  if (document) {
    for (const field in document) {
      if (field.indexOf(pattern) >= 0 && document[field]) {
        document[field] = new Date(String(document[field]))
      }
    }
  }
}
