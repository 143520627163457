import {
  GenderCodesEnum,
  LanguageCodesEnum,
  ProductCodesEnum,
  GroupsEnum,
} from '../..'

import {
  AccountCreatedEvent,
  AffiliationCodesEnum,
  AgentsEnum,
  EventsEnum,
  EventLogProductsEnum,
  ProfessionalId,
  ProfessionalInformation,
  SidesEnum,
  UserCreatedEvent,
  UserUpdatedEvent
} from '..'

export const AccountCreatedByAppEventFactory = (
  timestamp: Date,
  uid: string,
  email: string,
  language: LanguageCodesEnum,
  signupSource: ProductCodesEnum,
  affiliation: AffiliationCodesEnum = AffiliationCodesEnum.NONE
): AccountCreatedEvent => ({
  agent: AgentsEnum.USER,
  event: EventsEnum.ACCOUNT_CREATED,
  group: GroupsEnum.COGNI,
  product: EventLogProductsEnum.APP,
  side: SidesEnum.CLIENT_UX,
  timestamp,
  uid,
  email: email.toLowerCase(),
  language,
  signupSource,
  affiliation
})

export const AccountCreatedByDashEventFactory = (
  timestamp: Date,
  uid: string,
  email: string,
  language: LanguageCodesEnum,
  signupSource: ProductCodesEnum,
  affiliation: AffiliationCodesEnum = AffiliationCodesEnum.NONE
): AccountCreatedEvent => ({
  agent: AgentsEnum.USER,
  event: EventsEnum.ACCOUNT_CREATED,
  group: GroupsEnum.COGNI,
  product: EventLogProductsEnum.DASHBOARD,
  side: SidesEnum.CLIENT_UX,
  timestamp,
  uid,
  email: email.toLowerCase(),
  language,
  signupSource,
  affiliation
})

export const AccountCreatedByCorpDashEventFactory = (
  timestamp: Date,
  uid: string,
  email: string,
  language: LanguageCodesEnum
): AccountCreatedEvent => ({
  agent: AgentsEnum.SYSTEM,
  event: EventsEnum.ACCOUNT_CREATED,
  group: GroupsEnum.COGNI,
  product: EventLogProductsEnum.CORP_DASHBOARD,
  side: SidesEnum.SERVER,
  timestamp,
  uid,
  email: email.toLowerCase(),
  language,
  signupSource: ProductCodesEnum.COGNI_CORP_DASHBOARD,
  affiliation: AffiliationCodesEnum.NONE
})

export const UserCreatedEventByAppFactory = (
  timestamp: Date,
  uid: string,
  gender: GenderCodesEnum,
  birthday: string
): UserCreatedEvent => {
  const event: UserCreatedEvent = {
    agent: AgentsEnum.USER,
    event: EventsEnum.USER_CREATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp,
    uid,
    gender
  }
  if (birthday) {
    event.birthday = birthday
  }
  return event
}

export const UserCreatedEventByDashFactory = (
  timestamp: Date,
  uid: string,
  gender: GenderCodesEnum,
  cognicode: string,
  professionalInformation: ProfessionalInformation,
  professionalId?: ProfessionalId
): UserCreatedEvent => {
  const event: UserCreatedEvent = {
    agent: AgentsEnum.USER,
    event: EventsEnum.USER_CREATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp,
    uid,
    gender,
    cognicode,
    professionalInformation
  }
  if (professionalId) {
    event.professionalId = professionalId
  }
  return event
}

export const UserUpdatedEventByAppFactory = (
  timestamp: Date,
  uid: string,
  birthday?: string,
  gender?: string,
  language?: LanguageCodesEnum
): UserUpdatedEvent => {
  const event: UserUpdatedEvent = {
    agent: AgentsEnum.USER,
    event: EventsEnum.USER_UPDATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.APP,
    side: SidesEnum.CLIENT_UX,
    timestamp,
    uid
  }
  if (birthday) {
    event.birthday = birthday
  }
  if (gender) {
    event.gender = gender
  }
  if (language) {
    event.language = language
  }
  return event
}

export const UserUpdatedEventByDashFactory = (
  timestamp: Date,
  uid: string,
  affiliation?: AffiliationCodesEnum,
  gender?: string,
  language?: LanguageCodesEnum,
  professionalId?: ProfessionalId,
  professionalInformation?: ProfessionalInformation,
  cognicode?: string
): UserUpdatedEvent => {
  const event: UserUpdatedEvent = {
    agent: AgentsEnum.USER,
    event: EventsEnum.USER_UPDATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp,
    uid
  }
  if (gender) {
    event.gender = gender
  }
  if (language) {
    event.language = language
  }
  if (affiliation) {
    event.affiliation = affiliation
  }
  if (cognicode) {
    event.cognicode = cognicode
  }
  if (professionalId) {
    event.professionalId = professionalId
  }
  if (professionalInformation) {
    event.professionalInformation = professionalInformation
  }
  return event
}

export const UserUpdatedEventByCorpDashFactory = (
  timestamp: Date,
  uid: string,
  gender: string,
  language: LanguageCodesEnum
): UserUpdatedEvent => {
  return {
    agent: AgentsEnum.USER,
    event: EventsEnum.USER_UPDATED,
    group: GroupsEnum.COGNI,
    product: EventLogProductsEnum.CORP_DASHBOARD,
    side: SidesEnum.CLIENT_UX,
    timestamp,
    uid,
    gender,
    language
  }
}
