import {
  LanguageCodesEnum,
  PlatformsEnum
} from '../..'

import {
  AppMenuNotificationStatusesEnum,
  AppNotificationTypesEnum,
  PushNotificationDiscardReasonsEnum,
  PushNotificationStatuses,
  PushPermissionStatusesEnum,
  ReattemptFrequenciesEnum
} from '../enums'

export type AppBaseNotification = {
  readonly id: string
  readonly createdAtDate: string // we are storing in Realtime Database
  readonly type: AppNotificationTypesEnum
  readonly status: AppMenuNotificationStatusesEnum
  readonly title: string
  readonly line1: string
  readonly line2?: string
}

export type AppMenuNotificationSeen = AppBaseNotification & {
  readonly status: AppMenuNotificationStatusesEnum
  seenAtDate: string // we are storing in Realtime Database
}

export type AppTextOnlyMenuNotification = AppBaseNotification & {
  icon: string
  url?: string
}

export type AppIdentifiedMenuNotification = AppBaseNotification & {
  id: string // record id, therapist id...
}

export type PushNotificationsConfiguration = {
  readonly status: PushPermissionStatusesEnum
  readonly uid: string
  readonly deviceUuid: string
  readonly language: LanguageCodesEnum
  readonly platform: PlatformsEnum
  readonly appVersion: string
  readonly updatedAtDate: Date
  readonly token?: string
  readonly deniedAtDate?: Date
  readonly reattemptFrequency?: ReattemptFrequenciesEnum
  readonly reattemptAtDate?: Date
}

export type Push = {
  readonly id: string
  readonly type: AppNotificationTypesEnum
  readonly url?: string
  readonly expiresInMinutes: number
}

export type PushNotificationSent = Push & {
  readonly status: PushNotificationStatuses
  readonly pushId: string
  readonly sentAtDate: Date
}

export type PushNotificationReceived = {
  readonly status: PushNotificationStatuses
  readonly receivedAtDate: Date
}

export type PushNotificationActionTaken = {
  readonly status: PushNotificationStatuses
  readonly takenAtDate: Date
}

export type PushNotificationDiscarded = {
  readonly status: PushNotificationStatuses
  readonly discardedAtDate: Date
  readonly discardReason: PushNotificationDiscardReasonsEnum
}
