import {
  GenderCodesEnum,
  LanguageCodesEnum
} from '../..'

import { CorporateUserTypesEnum } from '..'

export type CorporationDocumentDataType = {
  id: string
  name: string
  psychoeducationSubscriptionId: string
  createdAtDate: Date
  updateAtDate: Date
}

export type CorporateDepartmentDocumentDataType = {
  id: string
  name: string
  cognicode: string
  corporationId: string
  psychoeducationSubscriptionId: string
  createdAtDate: Date
  updateAtDate: Date
  adminId?: string
  adminEmail?: string
}

export type CorpDashUserViewDocumentDataType = {
  corporateUserType: CorporateUserTypesEnum
  uid: string
  email: string
  language: LanguageCodesEnum
  formatVersion: number
  corpDashTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  gender?: GenderCodesEnum
  firstName?: string
  lastName?: string
  corpDashUpdatedAtDate?: Date
  psychoeducationSubscriptionId?: string
  passwordChangeViaBackendAllowed?: boolean
  cognicode?: string
  departmentName?: string
  departmentId?: string
  corporationId?: string
  corporationName?: string
}
