import { EducationLevelCodesEnum } from '../..'
import { ProfessionalIdTypesEnum, ProfessionCodesEnum } from '..'

export type BrazilCRM = {
  type: ProfessionalIdTypesEnum
  number: string
  state: string
}

export type ProfessionalId = BrazilCRM

export type ProfessionalInformation = {
  educationLevel: EducationLevelCodesEnum
  profession: ProfessionCodesEnum
  graduationYear?: number
  otherProfession?: string
}
