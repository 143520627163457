export enum StatusEnum {
  OK = 'OK',
  ERROR = 'ERROR'
}

//lowercase/uppercase to match Firebase codes
export enum ErrorsEnum {
  NULLISH_PARAMETER = 'nullish-parameter',
  INVALID_PARAMETER = 'invalid-parameter',
  INVALID_OPERATION = 'invalid-operation',
  MISSING_PARAMETERS = 'missing-parameters',
  NOT_ALLOWED = 'not-allowed',
  NOT_IMPLEMENTED = 'not-implemented',
  MISSING_DATA_PROPERTY = 'missing-data-property',
  PATIENT_NOT_FOUND = 'patient-not-found',
  PATIENT_ACCOUNT_DELETED = 'patient-account-deleted',
  THERAPIST_NOT_FOUND = 'therapist-not-found',
  DEPARTMENT_NOT_FOUND = 'department-not-found',
  CORPORATION_NOT_FOUND = 'corporation-not-found',
  THERAPIST_ACCOUNT_DELETED = 'therapist-account-deleted',
  DEPARTMENT_ACCOUNT_DELETED = 'department-account-deleted',
  INVITATION_NOT_FOUND = 'invitation-not-found',
  ACCESS_REQUEST_NOT_FOUND = 'access-request-not-found',
  INVITATION_ALREADY_EXISTS = 'invitation-already-exists',
  ACCESS_REQUEST_ALREADY_EXISTS = 'access-request-already-exists',
  INTERCONNECTION_NOT_FOUND = 'interconnection-not-found',
  INTERCONNECTION_ALREADY_EXISTS = 'interconnection-already-exists',
  USER_NOT_FOUND = 'user-not-found',
  REPEATED_COGNI_CODE = 'repeated-cogni-code',
  UNABLE_TO_GET_REDIRECTION_URL = 'unable-to-get-redirection-url',
  UNABLE_TO_PROCESS_REQUEST = 'unable-to-process-request',
  UNEXPECTED_EMAIL_EVENT = 'unexpected-email-event',

  // sendgrid
  SENDGRIG_PROBLEM_SENDING_EMAIL = 'sendgrid/problem-sending-email',

  //interplayers
  INTERPLAYERS_UNAVAILABLE = 'interplayers/unavailable',

  // firebase
  AUTH_EMAIL_ALREADY_EXISTS = 'auth/email-already-exists',
  AUTH_EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  AUTH_EMAIL_EQUALS_CURRENT = 'auth/email-equals-current',
  AUTH_EMAIL_IMPROPERLY_FORMATTED = 'auth/email-improperly-formatted',
  AUTH_INVALID_USER_TOKEN = 'auth/invalid-user-token',
  AUTH_INVALID_ACTION_CODE = 'auth/invalid-action-code',
  AUTH_INVALID_ACTION_MODE = 'auth/invalid-action-mode',
  AUTH_NETWORK_REQUEST_FAILED = 'auth/network-request-failed',
  AUTH_REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  AUTH_TOO_MANY_REQUESTS = 'auth/too-many-requests',
  AUTH_USER_DISABLED = 'auth/user-disabled',
  AUTH_USER_NOT_FOUND = 'auth/user-not-found',
  AUTH_EXPIRED_ACTION_CODE = 'auth/expired-action-code',
  AUTH_CUSTOM_ACTION_ALREADY_TAKEN = 'auth/custom-action-already-taken',
  AUTH_USER_UNAUTHENTICATED = 'auth/user-unauthenticated',
  AUTH_WRONG_PASSWORD = 'auth/wrong-password',
  AUTH_TOO_MANY_ATTEMPTS_TRY_LATER = 'auth/too-many-attempts-try-later',
  AUTH_PROBLEM_GETTING_EMAIL_VERIFICATION_LINK = 'action-link/problem-getting-email-verification-link',
  AUTH_PROBLEM_GETTING_PASSWORD_RESET_LINK = 'action-link/problem-getting-password-reset-link',

  AUTH_INTERNAL_TOO_MANY_ATTEMPTS_TRY_LATER = 'TOO_MANY_ATTEMPTS_TRY_LATER',
  AUTH_INTERNAL_EMAIL_ADDRESS_IMPROPERLY_FORMATTED = 'email address is improperly formatted',

  UNKNOWN = 'unknown'
}
