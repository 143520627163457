import {
  ActionLinkModesEnum,
  BaseResponse,
  DeviceInfo,
  LanguageCodesEnum
} from '../..'

import {
  AffiliationCodesEnum
} from '..'
import { BaseRequest} from '.'

export type CreateAppAccountRequest = BaseRequest & {
  email: string
  password: string
  language: LanguageCodesEnum
  device: DeviceInfo
  privacyPolicyVersionAccepted: string
  /** @deprecated */
  appTermsVersionAccepted?: string // TODO remove AFTER 3.1.2
  termsVersionAccepted?: string
  continueUrl: string
}
export type CreateDashAccountRequest = BaseRequest & {
  email: string
  password: string
  language: LanguageCodesEnum
  device: DeviceInfo
  privacyPolicyVersionAccepted: string
  termsVersionAccepted: string
  continueUrl: string
  affiliation: AffiliationCodesEnum
}
export type CreateAccountRequest =
  | CreateAppAccountRequest
  | CreateDashAccountRequest
export type CreateAccountResponse = BaseResponse & {
  uid?: string
}

export type SendPasswordResetActionLinkRequest = BaseRequest & {
  languageCode: LanguageCodesEnum
  email: string
  url: string
}

export type GetHttpRedirectionRequest = BaseRequest & {
  url: string
}

export type GetHttpRedirectionResponse = BaseResponse & {
  urlRedirection?: string
}

export type ValidateCustomActionLinkRequest = BaseRequest & {
  mode: ActionLinkModesEnum
  oobCode: string
  apiKey: string
}

export type ValidateCustomActionLinkResponse = BaseResponse & {
  [key: string]: string
}

export enum UnauthenticatedRequestTypesEnum {
  CREATE_ACCOUNT = 'create-account',
  SEND_PASSWORD_RESET_ACTION_LINK = 'send-password-reset-action-link',
  GET_SENDGRID_HTTP_REDIRECTION = 'get-sendgrid-http-redirection',
  VALIDATE_CUSTOM_ACTION_LINK = 'validate-custom-action-link'
}

export type UnauthenticatedCFRequest = {
  type: UnauthenticatedRequestTypesEnum
  request?:
    | CreateAccountRequest
    | SendPasswordResetActionLinkRequest
    | GetHttpRedirectionRequest
    | ValidateCustomActionLinkRequest
}