import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { DeviceInfo } from '../types/device-info';
import { AppConfigService } from './app-config.service';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private device: DeviceInfo;
  private languageCode: string;
  appVersion = 'test';
  appbuild = 'test'

  constructor(private appConfigService: AppConfigService ) {
    this.getAppVersion();
    this.loadDeviceInfo();
  }

  async getDevice(): Promise<DeviceInfo> {
    if (this.device){
      return this.device;
    }
    const info = await Device.getInfo();
    const id = await Device.getId();
    //const app = await App.getInfo(); // TODO
    const language = await this.getDeviceLanguage();
    this.device = new DeviceInfo(id.uuid, info.model, info.operatingSystem, info.osVersion, language, this.appVersion, this.appbuild, this.appConfigService.isProduction());
    return this.device;
  }

  async getDeviceUuid(): Promise<string> {
    if (this.device){
      return this.device.uuid;
    }
    await this.getDevice();
    return this.device.uuid;
  }

  async getDeviceLanguage() {
    if (this.languageCode){
      return this.languageCode;
    }
    const result = await Device.getLanguageCode();
    this.languageCode = result.value;
    return this.languageCode;
  }

  private async loadDeviceInfo(){
    await this.getDevice();
  }

  private async getAppVersion() {
    try {
      const app = await App.getInfo();
      this.appVersion = app.version;
      this.appbuild = app.build;
    }
    catch (error) {
      // not implemented for web
    }
  }
}
