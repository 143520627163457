// OTC stands for one-time code, but Firebase refers to it as OOB (out-of-band)
// we use these generators for both OTC and OOB codes
const generateAlphanumericOTC = (length: number) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return result
}

const generateNumericOTC = (length: number) => {
  let result = ''
  const characters = '0123456789'

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return result
}

export { generateAlphanumericOTC, generateNumericOTC }
