import { ActionLinkModesEnum, TaxPayerIdsEnum } from '..'

export type CNPJ = string
export type CPF = string

export type TaxPayerId = {
  type: TaxPayerIdsEnum
  id: CNPJ | CPF
}

export type MobilePhoneNumber = {
  internationalFormat: string
  countryCode: string
  verified: boolean
  nationalFormat?: string
}

export type DeviceInfo = {
  deviceUuid: string
  updatedAtDate: Date
  platform: string
  os: string
  osVersion: string
  webViewVersion: string
  model: string
  languageCode: string
  diskFree?: number
  appVersion?: string
  appBuild?: string
  uid?: string
}

export type Credential = {
  email: string
  password?: string
  rememberMe?: boolean
}

// oobCode is used by Firebase, and stands for out-of-band code
// since we use Action for both Firebase and Cogni's custom actions
// we'll use oobCode as the field name instead of otcCode
export type Action = {
  mode: ActionLinkModesEnum
  oobCode: string
  apiKey: string
  languageCode: string
  continueUrl?: string
}

export type CustomAction = Action & {
  protocol: 'http' | 'https'
  domain: string
  expiresAtDate: Date
  actionTaken: boolean
}

export type SignInAndVerifyAccountCustomAction = CustomAction & {
  encodedEmail: string
  encodedPassword: string
}
