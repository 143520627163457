import { ProductCodesEnum } from '../..'

import {
  EventLogProductsEnum,
  ProfessionCodeNumbersEnum,
  ProfessionCodesEnum
} from '..'

export const convertFromProfessionStringFormatToNumberFormat = (
  profession: ProfessionCodesEnum
): ProfessionCodeNumbersEnum => {
  switch (profession) {
    case ProfessionCodesEnum.PSYCHIATRY:
      return ProfessionCodeNumbersEnum.PSYCHIATRY
    case ProfessionCodesEnum.PSYCHOLOGY:
      return ProfessionCodeNumbersEnum.PSYCHOLOGY
    case ProfessionCodesEnum.NEUROLOGY:
      return ProfessionCodeNumbersEnum.NEUROLOGY
    case ProfessionCodesEnum.NEUROPSYCHIATRY:
      return ProfessionCodeNumbersEnum.NEUROPSYCHIATRY
    case ProfessionCodesEnum.NEUROPSYCHOLOGY:
      return ProfessionCodeNumbersEnum.NEUROPSYCHOLOGY
    case ProfessionCodesEnum.COACHING:
      return ProfessionCodeNumbersEnum.COACHING
    case ProfessionCodesEnum.OTHER:
      return ProfessionCodeNumbersEnum.OTHER
    case ProfessionCodesEnum.UNKNOWN:
      return ProfessionCodeNumbersEnum.UNKNOWN
    default:
      return ProfessionCodeNumbersEnum.UNKNOWN
  }
}

export const convertFromProfessionNumberFormatToStringFormat = (
  profession: ProfessionCodeNumbersEnum
): ProfessionCodesEnum => {
  switch (profession) {
    case ProfessionCodeNumbersEnum.PSYCHIATRY:
      return ProfessionCodesEnum.PSYCHIATRY
    case ProfessionCodeNumbersEnum.PSYCHOLOGY:
      return ProfessionCodesEnum.PSYCHOLOGY
    case ProfessionCodeNumbersEnum.NEUROLOGY:
      return ProfessionCodesEnum.NEUROLOGY
    case ProfessionCodeNumbersEnum.NEUROPSYCHIATRY:
      return ProfessionCodesEnum.NEUROPSYCHIATRY
    case ProfessionCodeNumbersEnum.NEUROPSYCHOLOGY:
      return ProfessionCodesEnum.NEUROPSYCHOLOGY
    case ProfessionCodeNumbersEnum.COACHING:
      return ProfessionCodesEnum.COACHING
    case ProfessionCodeNumbersEnum.OTHER:
      return ProfessionCodesEnum.OTHER
    case ProfessionCodeNumbersEnum.UNKNOWN:
      return ProfessionCodesEnum.UNKNOWN
    default:
      return ProfessionCodesEnum.UNKNOWN
  }
}

export const convertFromProductCodeToProduct = (
  product: ProductCodesEnum
): EventLogProductsEnum => {
  switch (product) {
    case ProductCodesEnum.COGNI_APP:
      return EventLogProductsEnum.APP
    case ProductCodesEnum.COGNI_DASHBOARD:
      return EventLogProductsEnum.DASHBOARD
    case ProductCodesEnum.COGNI_CORP_DASHBOARD:
      return EventLogProductsEnum.CORP_DASHBOARD
    case ProductCodesEnum.COGNI_PSYEDU:
      return EventLogProductsEnum.PSYEDU
    case ProductCodesEnum.COGNI_EMS:
      return EventLogProductsEnum.DASHBOARD
    case ProductCodesEnum.WEBSITE:
      return EventLogProductsEnum.WEBSITE
  }
}
