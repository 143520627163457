import {
  GroupsEnum,
  LanguageCodesEnum,
  ProductCodesEnum
} from '../..'

import {
  AffiliationCodesEnum,
  ProfessionalId,
  ProfessionalInformation
} from '..'

export enum ResultsEnum {
  OK = 'ok',
  ERROR = 'error'
}

export enum DeletionReasonsEnum {
  USER_REQUEST = 'user-request',
  EMAIL_UNVERIFIED = 'email-unverified'
}

export enum EventLogProductsEnum {
  APP = 'app',
  DASHBOARD = 'dashboard',
  CORP_DASHBOARD = 'corp-dashboard',
  PSYEDU = 'psychoeducation',
  WEBSITE = 'website'
}

export enum SidesEnum {
  CLIENT_UI = 'client-ui',
  CLIENT_UX = 'client-ux',
  SERVER = 'server'
}

export enum AgentsEnum {
  USER = 'user',
  SYSTEM = 'system',
  SUPPORT = 'support',
  SENDGRID = 'sendgrid',
  TWILIO = 'twilio',
  PAYPAL = 'paypal',
  STRIPE = 'stripe'
}

export enum EventsEnum {
  ACCOUNT_CREATED = 'account-created',
  ACCOUNT_VERIFIED = 'account-verified',
  ACCOUNT_DELETED = 'account-deleted',
  USER_CREATED = 'user-created',
  USER_UPDATED = 'user-updated',
  USER_DELETED = 'user-deleted',
  SIGNUP_ATTEMPTED = 'signup-attempted',
  SIGNIN_ATTEMPTED = 'signin-attempted',
  SIGNED_OUT = 'signed-out',
  EMAIL_CONFIRMED = 'email-confirmed',
  PASSWORD_RECOVERY_ATTEMPTED = 'password-recovery-attempted',
  PAGE_VIEWED = 'page-viewed',
  TERMS_VIEWS = 'terms-viewed',
  TERMS_ACCEPTED = 'terms-accepted',
  LANGUAGE_SELECTED = 'language-selected',
  SUPPORT_CONTACTED = 'support-contacted',
  SHARED_TO = 'shared-to',
  URL_OPENED = 'url-opened',

  DTR_CREATED = 'dtr-created',
  DTR_UPDATED = 'dtr-updated',
  DTR_DELETED = 'dtr-deleted',
  THOUGHT_CREATED = 'thought-created',
  THOUGHT_UPDATED = 'thought-updated',
  THOUGHT_DELETED = 'thought-deleted',

  INVITATION_SENT = 'invitation-sent',
  INVITATION_ACCEPTED = 'invitation-accepted',
  INVITATION_REFUSED = 'invitation-refused',
  INVITATION_CANCELLED = 'invitation-cancelled',

  INTERCONNECTION_CREATED = 'interconnection-created',
  INTERCONNECTION_TERMINATED = 'interconnection-terminated',

  RECONFIRMATION_REQUESTED = 'reconfirmation-requested',
  RECONFIRMATION_ACCEPTED = 'reconfirmation-accepted',
  RECONFIRMATION_REFUSED = 'reconfirmation-refused'
}

export enum CogniPageIdsEnum {
  WELCOME = 'welcome-demo',
  WELCOME_EMS = 'welcome-ems',
  LANGUAGE_OVERLAY = 'language-overlay',
  ADD_PATIENT_BAIT_OVERLAY = 'add-patient-bait-overlay',
  FAKE_PATIENT_PREVIEW = 'fake-patient-preview'
}

export type BaseEvent = {
  agent: AgentsEnum
  event: EventsEnum
  group: GroupsEnum
  product: EventLogProductsEnum
  side: SidesEnum
  timestamp: Date
}

export type AccountCreatedEvent = BaseEvent & {
  affiliation: AffiliationCodesEnum
  email: string
  language: LanguageCodesEnum
  signupSource: ProductCodesEnum
  uid: string
}

export type UserCreatedEvent = BaseEvent & {
  uid: string
  gender: string
  birthday?: string
  cognicode?: string
  professionalId?: ProfessionalId
  professionalInformation?: ProfessionalInformation
}

export type UserUpdatedEvent = BaseEvent & {
  uid: string
  affiliation?: AffiliationCodesEnum
  birthday?: string
  cognicode?: string
  gender?: string
  language?: LanguageCodesEnum
  professionalId?: ProfessionalId
  professionalInformation?: ProfessionalInformation
}

export type AccountVerifiedEvent = BaseEvent & {
  uid: string
}

export type AccountDeletedEvent = BaseEvent & {
  uid: string
  deletionReason: DeletionReasonsEnum
}

export type SignupAttemptedEvent = BaseEvent & {
  email: string
  deviceId: string
  result: ResultsEnum
  errorReason?: string
  /**
   * empty string in case of failure
   */
  uid: string
}

export type SigninAttemptedEvent = BaseEvent & {
  email: string
  deviceId: string
  rememberMe: boolean
  result: ResultsEnum
  errorReason?: string
  /**
   * empty string in case of failure
   */
  uid: string
}

export type SignedOutEvent = BaseEvent & {
  uid: string
}

// TODO add utm
export type PageViewedEvent = BaseEvent & {
  pageId: CogniPageIdsEnum
  language: LanguageCodesEnum
  /**
   * empty string when user is authenticated
   */
  deviceId: string
  /**
   * empty string when user is authenticated
   */
  locale: string
  /**
   * version of the app (x.x.x-x)
   * mandatory for welcome and main page
   */
  appVersion?: string
  /**
   * empty string when user is unauthenticated
   */
  uid: string
}

export type TermsViewedEvent = BaseEvent & {
  useTermsVersion: string
  /**
   * empty string when user is authenticated
   */
  deviceId: string
  /**
   * empty string when user is unauthenticated
   */
  uid: string
}

export type TermsAcceptedEvent = BaseEvent & {
  uid: string
  useTermsVersion: string
}

export type LanguageSelectedEvent = BaseEvent & {
  language: LanguageCodesEnum
  /**
   * empty string when user is authenticated
   */
  deviceId: string
  /**
   * accepts: overlay-combo, dropdown
   */
  uiSource: string
  /**
   * empty string when user is unauthenticated
   */
  uid: string
}

export type EmailConfirmedEvent = BaseEvent & {
  uid: string
}

export type PasswordRecoveryAttemptedEvent = BaseEvent & {
  email: string
  deviceId: string
  result: ResultsEnum
  errorReason?: string
}

//TODO complete
export type SupportContactedEvent = BaseEvent & {
  name?: string
  email?: string
  /**
   * empty string when user is authenticated
   */
  deviceId: string
  /**
   * empty string when user is unauthenticated
   */
  uid: string
}

export type SharedToInstagramEvent = BaseEvent & {
  language: LanguageCodesEnum
  /**
   * empty string when user is authenticated
   */
  deviceId: string
  /**
   * empty string when user is unauthenticated
   */
  uid: string
}

export type UrlOpenedEvent = BaseEvent & {
  /**
   * empty string when user is authenticated
   */
  deviceId: string
  /**
   * empty string when user is unauthenticated
   */
  url: string
  uid: string
}

export type InterconnectionEvent = BaseEvent & {
  patientId: string
  therapistId: string
  interconnectionId: string
}

export type InvitationEvent = BaseEvent & {
  patientId: string
  therapistId: string
  invitationId: string
}

export type ReconfirmationEvent = BaseEvent & {
  patientId: string
  therapistId: string
  interconnectionId: string
}

export type DTREvent = BaseEvent & {
  uid: string
  [key: string]: unknown
}

export type DepartmentAccessRequestEvent = BaseEvent & {
  employeeId: string
  departmentId: string
  accessRequestId: string
  corporationId: string
}

export type DepartmentInterconnectionEvent = BaseEvent & {
  employeeId: string
  departmentId: string
  interconnectionId: string
  corporationId: string
}

export type Event =
  | SignupAttemptedEvent
  | SigninAttemptedEvent
  | SignedOutEvent
  | AccountCreatedEvent
  | AccountVerifiedEvent
  | AccountDeletedEvent
  | PageViewedEvent
  | TermsViewedEvent
  | TermsAcceptedEvent
  | LanguageSelectedEvent
  | EmailConfirmedEvent
  | PasswordRecoveryAttemptedEvent
  | SupportContactedEvent
  | SharedToInstagramEvent
  | UrlOpenedEvent
  | DTREvent
  | InterconnectionEvent
  | InvitationEvent
  | DepartmentAccessRequestEvent
  | ReconfirmationEvent
  | DepartmentInterconnectionEvent
