import {
  BaseResponse,
  GenderCodesEnum,
  LanguageCodesEnum,
  MessageMethodsEnum,
  MessageTypesEnum,
  MobilePhoneNumber
} from '../..'

import {
  AffiliationCodesEnum,
  AllUserFieldsOptional,
  AppUserView,
  DashUserView,
  CorpDashUserViewDocumentDataType,
  InvitationStatusesEnum,
  MembershipStatusesEnum,
  ProfessionalId,
  ProfessionalInformation
} from '..'

import { BaseRequest } from '.'
import { AccessRequestStatusesEnum } from '../enums'
import { BatchInvitationType } from '../types'

export type CreateBaseUserRequest = BaseRequest & {
  firstName: string
  lastName: string
  gender: GenderCodesEnum
}
export type CreateAppUserRequest = CreateBaseUserRequest & {
  birthday: string
}
export type CreateDashUserRequest = CreateBaseUserRequest & {
  professionalId?: ProfessionalId
  professionalInformation?: ProfessionalInformation
  mobilePhoneNumber?: MobilePhoneNumber
  profilePicture?: string
}
export type CreateUserRequest = CreateAppUserRequest | CreateDashUserRequest
export type CreateUserResponse = BaseResponse & {
  cognicode?: string
}

export type GetUserViewRequest = BaseRequest

export type GetUserViewResponse = BaseResponse & {
  user?: DashUserView | AppUserView | CorpDashUserViewDocumentDataType | AllUserFieldsOptional // TODO: do we need AllUserFieldsOptional
}

export type ChangePasswordRequest = BaseRequest & {
  password: string
}

export type InviteTherapistRequest = BaseRequest & {
  /** @deprecated */
  cogniCode?: string
  cognicode: string
}

export type RespondToInvitationRequest = BaseRequest & {
  invitationId: string
  status: InvitationStatusesEnum
}

export type CancelTherapistInvitationRequest = BaseRequest & {
  invitationId: string
}

export type RequestAccessToDepartmentRequest = BaseRequest & {
  cognicode: string
}

export type CancelAccessToDepartmentRequestRequest = BaseRequest & {
  accessRequestId: string
}

export type RespondToEmployeeAccessRequest = BaseRequest & {
  accessRequestId: string
  status: AccessRequestStatusesEnum
}

export type StopSharingWithTherapistRequest = BaseRequest & {
  interconnectionId: string
}

export type RespondToReconfirmationRequest = BaseRequest & {
  confirmed: boolean
}

export type StopSharingWithDepartmentRequest = BaseRequest & {
  interconnectionId: string
}

export type BatchInviteEmployeesRequest = BaseRequest & {
  list: BatchInvitationType[]
  corporationId: string
  departmentId: string
}

export type SendMessageRequest = BaseRequest & {
  method: MessageMethodsEnum
  type: MessageTypesEnum
  to: string
  language: string
}

export type SendMessageResponse = BaseResponse & {
  code?: string
}

/** @deprecated */
export type UpdateUserRequest = BaseRequest & {
  firstName?: string
  lastName?: string
  language?: LanguageCodesEnum
  gender?: GenderCodesEnum
  birthday?: string
  appOnboarding?: number
  appTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
}

export type UpdateAppUserProfileRequest = BaseRequest & {
  firstName: string
  lastName: string
  language: LanguageCodesEnum
  gender: GenderCodesEnum
  birthday: string
}
export type UpdateDashUserProfileRequest = BaseRequest & {
  firstName?: string
  lastName?: string
  language?: LanguageCodesEnum
  gender?: GenderCodesEnum
  professionalId?: ProfessionalId
  professionalInformation?: ProfessionalInformation
  mobilePhoneNumber?: MobilePhoneNumber
  affiliation?: AffiliationCodesEnum
  termsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  profilePicture?: string
}
export type UpdateCorpDashUserProfileRequest = BaseRequest & {
  firstName: string
  lastName: string
  gender: GenderCodesEnum
  termsVersionAccepted: string
  privacyPolicyVersionAccepted: string
}
export type UpdateUserProfileRequest =
  | UpdateAppUserProfileRequest
  | UpdateDashUserProfileRequest
  | UpdateCorpDashUserProfileRequest
export type UpdateUserProfileResponse = BaseResponse & {
  cognicode?: string
}

export type SendEmailVerificationActionLinkRequest = BaseRequest & {
  languageCode: LanguageCodesEnum
  url: string
}

export type VerifyEMSMembershipRequest = BaseRequest & {
  cpf: string
}

export type VerifyEMSMembershipResponse = BaseResponse & {
  membership?: MembershipStatusesEnum
}

export type UpdateUserTermsAndPolicyRequest = BaseRequest & {
  termsVersionAccepted: string
  privacyPolicyVersionAccepted: string
}

export enum AuthenticatedRequestTypesEnum {
  CREATE_USER = 'create-user',
  /** @deprecated */
  GET_USER = 'get-user', // TODO remove AFTER 3.1.2
  GET_USER_VIEW = 'get-user-view', // TODO remove AFTER 3.1.2
  /** @deprecated */
  UPDATE_USER = 'update-user',
  UPDATE_USER_PROFILE = 'update-user-profile',
  UPDATE_USER_TERMS_AND_POLICY = 'update-user-terms-and-policy',

  INTERPLAYERS_VALIDATE_CPF = 'interplayers-validate-cpf',

  INVITE_THERAPIST = 'invite-therapist',
  RESPOND_TO_PATIENT_INVITATION = 'respond-to-patient-invitation',
  CANCEL_THERAPIST_INVITATION = 'cancel-therapist-invitation',
  STOP_SHARING_WITH_THERAPIST = 'stop-sharing-with-therapist',
  
  BATCH_INVITE_EMPLOYEES = 'batch-invite-employees',
  REQUEST_ACCESS = 'request-access-to-department',
  RESPOND_TO_EMPLOYEE_ACCESS_REQUEST = 'respond-to-employee-access-request',
  CANCEL_ACCESS_REQUEST = 'cancel-access-to-department-request',
  STOP_SHARING_WITH_DEPARTMENT = 'stop-sharing-with-deparment',

  RESPOND_TO_RECONFIRMATION = 'respond-to-reconfirmation',

  SEND_MESSAGE = 'send-message',
  SEND_EMAIL_VERIFICATION_ACTION_LINK = 'send-email-verification-action-link',
  CHANGE_PASSWORD = 'change-password'
}

export type AuthenticatedCFRequest = {
  type: AuthenticatedRequestTypesEnum
  request?:
    | BaseRequest
    | GetUserViewRequest
    | CreateUserRequest
    | UpdateUserRequest
    | UpdateUserProfileRequest 
    | InviteTherapistRequest
    | RespondToInvitationRequest
    | RespondToReconfirmationRequest
    | CancelTherapistInvitationRequest
    | StopSharingWithTherapistRequest
    | StopSharingWithDepartmentRequest
    | RequestAccessToDepartmentRequest
    | CancelAccessToDepartmentRequestRequest
    | RespondToEmployeeAccessRequest
    | BatchInviteEmployeesRequest
    | SendMessageRequest
    | SendEmailVerificationActionLinkRequest
    | VerifyEMSMembershipRequest // TODO
    | UpdateUserTermsAndPolicyRequest
    | ChangePasswordRequest
}
